import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from './http.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private readonly FCM_API_URL = 'https://fcm.googleapis.com/v1/projects/myrideus-6b99c/messages:send';

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private http: HttpService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('Error:', error);
        this.toastr.error(error?.error?.message ? error?.error?.message : error?.error?.error);

        if (error.status === 401) {
          // Check if the request is for the notification URL
          if (request.url === this.FCM_API_URL) {
            // If it is, fetch a new token and retry the request
            return from(this.http.getNewAccessToken()).pipe(
              switchMap((newToken: any) => {
                localStorage.setItem('fcm', newToken?.accessToken);

                const clonedRequest = request.clone({
                  headers: request.headers.set('Authorization', `Bearer ${newToken?.accessToken}`)
                });

                return next.handle(clonedRequest);
              }),
              catchError(innerError => {
                console.log('Inner Error:', innerError);
                return throwError(innerError);
              })
            );
          } else {
            this.logoutUser();
          }
        }

        return throwError(error);
      })
    );
  }

  async logoutUser(){
    localStorage.clear();
    this.router.navigateByUrl('auth/login');
  }
}
