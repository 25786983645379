import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { Socket, io } from 'socket.io-client';
import { HttpService } from './http.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  private socket: Socket;
  private readonly FCM_API_URL = 'https://fcm.googleapis.com/v1/projects/myrideus-6b99c/messages:send';
  private readonly SERVER_KEY = 'AAAAVp2jOrk:APA91bFrbeOUEoSRijqjYZez6lGwwFvNUFBRqfjHVhAnW2EoeEl6VLdC72uuoFTcS1m6WLXGeaHJscV4oiWh6jck4T_45PXP7-yNaSqzFRtehLEMyTyvp88sjXH3lvtDsblI8HdIodbX';


  permissions = new BehaviorSubject([]);
  token = new BehaviorSubject(undefined);

  constructor(private http: HttpService, private toastr: ToastrService, private httpClient: HttpClient) {
    this.socket = io('https://myride-admin-socket-746521ae2e55.herokuapp.com/', {
      withCredentials: true,
      transports: ['websocket', 'polling']
    });
  }



  sendMessage(messageData: any) {
    this.socket.emit('help-message', messageData);
  }

  // Receive messages from Socket.IO
  getMessages(): Observable<{ user: string, message: string }> {
    return new Observable<{ user: string, message: string }>(observer => {
      this.socket.on('help-message', (data) => {

        console.log(data, 'dfdf')
        observer.next(data);
      });

      return () => {
        this.socket.disconnect();
      };
    });
  }

  fileUploadHttp(event: any): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const MAX_WIDTH = 800;
          const MAX_HEIGHT = 600;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;

          if (ctx) {
            ctx.drawImage(img, 0, 0, width, height);
            const base64String = canvas.toDataURL('image/webp', 0.8);
            let substringToRemove = 'data:image/webp;base64,';

            let resultAfterModify = base64String.replace(substringToRemove, '');
            if (base64String) {
              this.http
                .post(
                  'auth/imageUploadBase64',
                  { image: resultAfterModify },
                  true
                )
                .subscribe(
                  (response: any) => {
                    resolve(response);
                  },
                  (error) => {
                    reject(error);
                  }
                );
            }
          }
        };
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  }


  sendNotification(token: string, message: string) {
    const payload = {
      "message": {
        "token": token,
        "notification": {
          "body": message,
          "title": 'New Message',
          // icon: 'https://canningsolicitors.ie/wp-content/uploads/2021/12/00-user-dummy-200x200-1.png'
        },
        "data": {
          "screen_name": "Help"
        }
      }
    };

    console.log('Notification Payload:', payload); // Log the payload object

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': `key=${this.SERVER_KEY}`
      'Authorization': `Bearer ${localStorage.getItem('fcm')}`
    });

    return this.httpClient.post(this.FCM_API_URL, payload, { headers }).toPromise();
  }



}
