import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HelperService } from './helper.service';

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root',
})
export class NavService implements OnDestroy {
  permissions: any;
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router, private Help: HelperService) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });

      this.Help.permissions.subscribe((res:any)=>{
        console.log(res, 'permissionss')
        this.permissions = res
        this.updateMenuItems(res);
      })
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  
  MENUITEMS: Menu[] = [
    // { path: '/dashboard/home', icon: 'home', title: 'Home', type: 'link' },
    // { path: '/users', icon: 'users', title: 'Users', type: 'link' },    

    {
      title: 'Drivers',
      type: 'sub',
      active: true,
      icon: 'user-check',
      children: [
        // {
        //   path: '/dashboard/driver',
        //   title: 'Drivers',
        //   type: 'link'
        // },
        // {
        //   path: '/dashboard/requests',
        //   title: 'Driver Requests',
        //   type: 'link'
        // },
      ],
    },
    // { path: '/dashboard/ride', icon: 'key', title: 'Rides', type: 'link' },
    // { path: '/dashboard/service', icon: 'truck', title: 'Services', type: 'link' },
    
    // { path: '/dashboard/role-management', icon: 'sliders', title: 'Role Management', type: 'link' },
    // localStorage.getItem('role') === 'admin' ? 
    // { 
    //   path: '/dashboard/role-management', 
    //   icon: 'sliders', 
    //   title: 'Role Management', 
    //   type: 'link' 
    // } : null,

    // { path: '/dashboard/stripe_payments', icon: 'dollar-sign', title: 'Stripe Payments', type: 'link' },
    // { path: '/dashboard/queries', icon: 'alert-octagon', title: 'Queries', type: 'link' },
    // { path: '/dashboard/waiting', icon: 'clock', title: 'Waiting Fee', type: 'link' },
    // { path: '/dashboard/promo', icon: 'percent', title: 'Promos', type: 'link' },
    // { path: '/dashboard/notifications', icon: 'bell', title: 'Notifications', type: 'link' },
    // { path: '/dashboard/terms&condition', icon: 'clipboard', title: 'Terms And Condition', type: 'link' },
    // { path: '/dashboard/privacy_policy', icon: 'git-pull-request', title: 'Privacy Policy', type: 'link' },
    // { path: '/dashboard/how_to_use', icon: 'target', title: 'How To Use', type: 'link' },
    // { path: '/dashboard/help', icon: 'help-circle', title: 'Help', type: 'link' },
    

  ];

  MEGAMENUITEMS: Menu[] = [
    {
      title: 'Error Pages',
      type: 'sub',
      active: true,
      children: [
        {
          path: '/error-page/error-400',
          title: 'Error Page 400',
          type: 'link',
        },
        {
          path: '/error-page/error-401',
          title: 'Error Page 401',
          type: 'link',
        },
        {
          path: '/error-page/error-403',
          title: 'Error Page 403',
          type: 'link',
        },
        {
          path: '/error-page/error-404',
          title: 'Error Page 404',
          type: 'link',
        },
        {
          path: '/error-page/error-500',
          title: 'Error Page 500',
          type: 'link',
        },
        {
          path: '/error-page/error-503',
          title: 'Error Page 503',
          type: 'link',
        },
      ],
    },
    {
      title: 'Authentication',
      type: 'sub',
      active: false,
      children: [
        {
          path: '/authentication/simple-login',
          title: 'Login Simple',
          type: 'link',
        },
        {
          path: '/authentication/login-with-background-image',
          title: 'Login BG Image',
          type: 'link',
        },
        {
          path: '/authentication/login-with-background-video',
          title: 'Login BG Video',
          type: 'link',
        },
        {
          path: '/authentication/simple-register',
          title: 'Simple Register',
          type: 'link',
        },
        {
          path: '/authentication/register-with-background-image',
          title: 'Register BG Image',
          type: 'link',
        },
        {
          path: '/authentication/register-with-background-video',
          title: 'Register BG Video',
          type: 'link',
        },
      ],
    },
    {
      title: 'Usefull Pages',
      type: 'sub',
      active: false,
      children: [
        { path: '/search-pages', title: 'Search Pages', type: 'link' },
        {
          path: '/authentication/unlock-user',
          title: 'Unlock User',
          type: 'link',
        },
        {
          path: '/authentication/forgot-password',
          title: 'Forgot Password',
          type: 'link',
        },
        {
          path: '/authentication/reset-password',
          title: 'Reset Password',
          type: 'link',
        },
        { path: '/maintenance', title: 'Maintenance', type: 'link' },
      ],
    },
    {
      title: 'Email templates',
      type: 'sub',
      active: false,
      children: [
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/basic-template.html',
          title: 'Basic Email',
          type: 'extTabLink',
        },
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/email-header.html',
          title: 'Basic With Header',
          type: 'extTabLink',
        },
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/template-email.html',
          title: 'Ecomerce Template',
          type: 'extTabLink',
        },
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/template-email-2.html',
          title: 'Email Template 2',
          type: 'extTabLink',
        },
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html',
          title: 'Ecommerce Email',
          type: 'extTabLink',
        },
        {
          path: 'http://admin.pixelstrap.com/cuba/theme/email-order-success.html',
          title: 'Order Success',
          type: 'extTabLink',
        },
      ],
    },
    {
      title: 'Coming Soon',
      type: 'sub',
      active: false,
      children: [
        { path: '/coming-soon/simple', title: 'Coming Simple', type: 'link' },
        {
          path: '/coming-soon/simple-with-bg-img',
          title: 'Coming BG Image',
          type: 'link',
        },
        {
          path: '/coming-soon/simple-with-bg-vid',
          title: 'Coming BG Video',
          type: 'link',
        },
      ],
    },
  ];
  

  updateMenuItems(permissions: string[]): void {
    if (permissions.includes('drivers')) {
      const driversMenu = this.MENUITEMS.find(item => item.title === 'Drivers');
      if (driversMenu && driversMenu.children) {
        if (!driversMenu.children.some(child => child.path === '/dashboard/driver')) {
          driversMenu.children.push({ path: '/dashboard/driver', title: 'Drivers', type: 'link' });
        }
      }
    }

    if (permissions.includes('driver-requests')) {
      const requestMenu = this.MENUITEMS.find(item => item.title === 'Drivers');
      if (requestMenu && requestMenu.children) {
        if (!requestMenu.children.some(child => child.path === '/dashboard/requests')) {
          requestMenu.children.push({ path: '/dashboard/requests', title: 'Driver Requests', type: 'link' });
        }
      }
    }

    if (permissions.includes('home')) {
      const HomwMenu = this.MENUITEMS.find(item => item.title === 'Home');
      if (!HomwMenu) {
        this.MENUITEMS.unshift({ path: '/dashboard/home', icon: 'home', title: 'Home', type: 'link' });
      }
    }

    if (permissions.includes('users')) {
      const usersMenu = this.MENUITEMS.find(item => item.title === 'Users');
        if (!usersMenu) {
          this.MENUITEMS.push({ path: '/users', icon: 'users', title: 'Users', type: 'link' });
        }

    }

    if (permissions.includes('rides')) {
      const RideMenu = this.MENUITEMS.find(item => item.title === 'Rides');
        if (!RideMenu) {
          this.MENUITEMS.push( { path: '/dashboard/ride', icon: 'key', title: 'Rides', type: 'link' });
        }
    }

    if (permissions.includes('services')) {
      const ServiceMenu = this.MENUITEMS.find(item => item.title === 'Services');
      if (!ServiceMenu) {
          this.MENUITEMS.push({ path: '/dashboard/service', icon: 'truck', title: 'Services', type: 'link' });
      }
    }

    if (permissions.includes('role-management')) {
      const RoleMenu = this.MENUITEMS.find(item => item.title === 'Role Management');
      if (!RoleMenu) {
          this.MENUITEMS.push({ path: '/dashboard/role-management', icon: 'sliders', title: 'Role Management', type: 'link' });
      }
    }



    // if (permissions.includes('role-managment')) {
    //   const RoleeMenuIndex = this.MENUITEMS.findIndex(item => item.title === 'Role Managment');
    //   if (RoleeMenuIndex === -1) {
    //     const servIndex = this.MENUITEMS.findIndex(item => item.title === 'Services');
    //     if (servIndex !== -1) {
    //       this.MENUITEMS.splice(servIndex + 1, 0, { path: '/dashboard/role-management', icon: 'slider', title: 'Role Managment', type: 'link' });
    //     }
    //   }
    // }



    if (permissions.includes('stripe-payments')) {
      const StripeMenu = this.MENUITEMS.find(item => item.title === 'Stripe Payments');
      if (!StripeMenu) {
          this.MENUITEMS.push({ path: '/dashboard/stripe_payments', icon: 'dollar-sign', title: 'Stripe Payments', type: 'link' });
        
      }
    }

    

    if (permissions.includes('queries')) {
      const QueriesMenu = this.MENUITEMS.find(item => item.title === 'Queries');
      if (!QueriesMenu) {
          this.MENUITEMS.push( { path: '/dashboard/queries', icon: 'alert-octagon', title: 'Queries', type: 'link' });
      }
    }

    // if (permissions.includes('waiting-fee')) {
    //   const WaitingMenu = this.MENUITEMS.find(item => item.title === 'Waiting Fee');
    //   if (!WaitingMenu) {
    //       this.MENUITEMS.push({ path: '/dashboard/waiting', icon: 'clock', title: 'Waiting Fee', type: 'link' });
    //   }
    // }

    if (permissions.includes('withdraw')) {
      const WithdrawMenu = this.MENUITEMS.find(item => item.title === 'Driver Withraws');
      if (!WithdrawMenu) {
          this.MENUITEMS.push({ path: '/dashboard/withdraw', icon: 'pocket', title: 'Driver Withraws', type: 'link' });
      }
    }

    if (permissions.includes('promos')) {
      const PromosMenu = this.MENUITEMS.find(item => item.title === 'Promos');
      if (!PromosMenu) {
          this.MENUITEMS.push({ path: '/dashboard/promo', icon: 'percent', title: 'Promos', type: 'link' });
      }
    }

    if (permissions.includes('notifications')) {
      const NotificationsMenu = this.MENUITEMS.find(item => item.title === 'Notifications');
      if (!NotificationsMenu) {
          this.MENUITEMS.push({ path: '/dashboard/notifications', icon: 'bell', title: 'Notifications', type: 'link' });
        }
    }

    if (permissions.includes('terms&condition')) {
      const TermssMenu = this.MENUITEMS.find(item => item.title === 'Terms And Condition');
      if (!TermssMenu) {
          this.MENUITEMS.push({ path: '/dashboard/terms&condition', icon: 'clipboard', title: 'Terms And Condition', type: 'link' });
      }
    }

    if (permissions.includes('privacy_policy')) {
      const PrivacysMenu = this.MENUITEMS.find(item => item.title === 'Privacy Policy');
      if (!PrivacysMenu) {
          this.MENUITEMS.push({ path: '/dashboard/privacy_policy', icon: 'git-pull-request', title: 'Privacy Policy', type: 'link' });
      }
    }

    if (permissions.includes('how_to_use')) {
      const HowsMenu = this.MENUITEMS.find(item => item.title === 'How To Use');
      if (!HowsMenu) {
          this.MENUITEMS.push( { path: '/dashboard/how_to_use', icon: 'target', title: 'How To Use', type: 'link' });
      }
    }

    if (permissions.includes('help')) {
      const HelpMenu = this.MENUITEMS.find(item => item.title === 'Help');
      if (!HelpMenu) {
          this.MENUITEMS.push( { path: '/dashboard/help', icon: 'help-circle', title: 'Help', type: 'link' });
      }
    }

    if (permissions.includes('chat-support')) {
      const ChatMenu = this.MENUITEMS.find(item => item.title === 'Chat Support');
      if (!ChatMenu) {
          this.MENUITEMS.push( { path: '/dashboard/chat-support', icon: 'help-circle', title: 'Chat Support', type: 'link' });
      }
    }

    if (permissions.includes('chat-support')) {
      const ChatMenu = this.MENUITEMS.find(item => item.title === 'Setting');
      if (!ChatMenu) {
          this.MENUITEMS.push( { path: '/dashboard/setting', icon: 'settings', title: 'Setting', type: 'link' });
      }
    }

  }

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
}
