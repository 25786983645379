import { Component, PLATFORM_ID, Inject, ChangeDetectorRef } from "@angular/core";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { map, delay, withLatestFrom } from "rxjs/operators";
import { DOCUMENT } from '@angular/common';
import { LoaderService } from "./shared/services/loader.service";
import { HttpService } from "src/app/shared/services/http.service";
import { HelperService } from "./shared/services/helper.service";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public permissions;
  messageSubscription: Subscription;
  readonly adminUserId = 1; // Assuming admin's user ID is 2
  private permissionDeniedLogged: boolean = false;


  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map((v) => v[1])
  );
  public show: boolean = false;
  constructor(
    private http: HttpService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private cd: ChangeDetectorRef,
    private Help: HelperService,
    private toastr: ToastrService,
    private router: Router
  ) { }




  ngOnInit() {
    const token: any = localStorage.getItem('token');
    this.Help.token.subscribe((res:any) => {
      console.log('subs token', res)
      if (res || token) {
        this.observe()
        this.loadData().then((r: any) => {
  
          const hasChatSupportPermission = this.hasPermission('chat-support');
          console.log(hasChatSupportPermission, 'fdsf')
          this.messageSubscription = this.Help.getMessages().subscribe((data: any) => {
            console.log(data, 'data from app')
            if (hasChatSupportPermission && data?.user?._id !== this.adminUserId) {
              const toastrRef = this.toastr.info(
                `Message from ${data?.user?.name}: ${data?.text}`,
                'New Message'
              );
  
              toastrRef.onTap.subscribe(() => {
                this.navigateToChat(data.user?._id);
              });
            }
          });
        });
      }
    })
  }

  hasPermission(permission: string): boolean {
    if (this.permissions && this.permissions.includes(permission)) {
      return true;
    } else {
      if (!this.permissionDeniedLogged) {
        console.log("Permission denied");
        this.permissionDeniedLogged = true;
      }
      return false;
    }
  }

  navigateToChat(userId: number) {
    // this.router.navigate(['/dashboard/chat-support'], { id: userId });
    this.router.navigateByUrl('/dashboard/chat-support', { state: { id: userId } });
  }



  async loadData() {
    await Promise.all([this.getPermissions()]);
  }

  async observe() {
    LoaderService.loader.subscribe((res: any) => {
      this.show = res;
      if (this.show == true) {
        this.document.body.classList.add('bodyLoader');
      } else {
        this.document.body.classList.remove('bodyLoader');
      }
      this.cd.detectChanges();
    });


  }

  async getPermissions() {
    try {
      this.http.get("get-user-permission-by-admin", true).subscribe((res: any) => {
        console.log('from app', res)
        this.permissions = res?.permissions;
        this.Help.permissions.next(this.permissions)
      });
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  }


}
