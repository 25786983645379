<!-- my-account.component.html -->
<div class="media profile-media">
  <div class="media-body">
    <p class="mb-0 font-roboto">
      {{ displayRole }}
      <i class="middle fa fa-angle-down"></i>
    </p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li>
    <a (click)="logoutUser()">
      <app-feather-icons [icon]="'log-in'"></app-feather-icons>
      <span>Log out</span>
    </a>
  </li>
</ul>
