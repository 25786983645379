export const environment = {
  production: true,
  baseUrl:'https://buybestthemes.com/mobile_app_api/myRide/api/',

   firebase: {
    apiKey: "AIzaSyA0PhqfWKH4LKvGWFrQroqhlVFrotUzmzM",
    authDomain: "myride-9f6cb.firebaseapp.com",
    projectId: "myride-9f6cb",
    storageBucket: "myride-9f6cb.appspot.com",
    messagingSenderId: "372011907769",
    appId: "1:372011907769:web:791c5fb58b4ab649253968",
    measurementId: "G-K9Y4Z4JRF2",
    vapidKey: "BFR3DHkEcosczcDuFjAqLJuI46z1musLYFTq5HchueERLOkOI3nUYgavsmkA-s8B0uE83EluM8bRdA7WLiL7_JI"
  },
};

